import { useEffect, useState } from "react"
import { useWebContext } from "../../_contexts"
import { useAxios } from "../../_utility"
import { requestsDataProps, StatusResponseProps, suggestionResultProps, TemplateResultProps } from "./types"

const apiUrl = `${process.env.REACT_APP_AMP_API_SLUG}`
const getSuggestionUrl = `${apiUrl}/ampPages/abTesting/loadCache`
const getRequestUrl = `${apiUrl}/ampPages/abTesting/advanceSearch`
const downloadRequestCsv = `${apiUrl}/ampPages/abTesting/downloadFile/`

type fetchDataProps = {
  page?: number,
  userId?: string[],
  status?: string[],
  templateId?: string[],
  tillDate?: string,
  fromDate?: string,
  requestId?: string[]
}

export function useApiTemplateRequests() {
  const [requestData, setRequestData] = useState<requestsDataProps[]>([])
  const [StopApi, setStopApi] = useState(false)

  const { response, isLoading, error, fetch } = useAxios({
    url: `${getRequestUrl}`,
    dataType: 'json',
    method: 'post'
  })

  function fetchData({
    page = 1,
    userId = [],
    status = [],
    templateId = [],
    requestId = [],
    tillDate = "",
    fromDate = ""

  }: fetchDataProps) {
    if (page === 1) {
      setRequestData([])
    }

    fetch({
      params: {
        page: page
      },
      data: {
        userId: userId,
        status: status,
        templateId: templateId,
        tillDate: tillDate,
        fromDate: fromDate,
        requestId: requestId
      }
    })
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [])

  useEffect(() => {
    let reqData = response ? response.result : []
    if (response && response.result.length === 0) {
      setStopApi(true)
    }
    else {
      if(response && response.result.length <10){setStopApi(true)}
      else{
      setStopApi(false)
    }
    }
    let all_data = [...requestData, ...reqData]
    let Unique_data = all_data.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.requestId === value.requestId
      ))
    )
    reqData?.length > 0 && setRequestData(Unique_data)
  }, [response])

  return { requestData, error, isLoading, fetchData, StopApi }
}

export function useGetSuggestion() {
  const [suggestionResponse, setSuggestion] = useState<suggestionResultProps>()
  const [TemplateResponse, setTemplate] = useState<TemplateResultProps[]>()
  const [StatusResponse, setStatus] = useState<StatusResponseProps[]>()
  const { response } = useAxios({
    url: `${getSuggestionUrl}`,
    initFetch: true
  })

  useEffect(() => {
    if (response) {
      const { templates, status, ...rest } = response.result
      setSuggestion(rest)
      setTemplate(templates)
      setStatus(status)
    }
  }, [response])
  return { suggestionResponse, TemplateResponse, StatusResponse }
}

type downloadCsvProps = {
  requestId: string,
  fileName: string,
}

export function useDownloadCsv({ requestId, fileName }: downloadCsvProps) {
  const { notification } = useWebContext()

  const { response, isLoading, error, fetch } = useAxios({
    url: `${downloadRequestCsv}${requestId}`,

    method: "get",
  })

  useEffect(() => {
    if (response) {
      if(response.status === "Failed"){
        notification(response.description,'error')
        return 
      }

      const a = document.createElement('a')
      a.download = fileName
      a.href = response.result[0]
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }, [response])

  return { fetch }

}