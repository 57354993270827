import { LoginRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useLoginLogout } from "./userAccess.api";

const SignIn: React.FC = () => {
  const { isLoading: googleLoading, signInWithGoogle } = useLoginLogout()

  return (
    <Box sx={{
      width: '100%',
      maxWidth: '350px',
      mt: [7]
    }}>
      <Box sx={{ textAlign: 'center' }}>
        <LoadingButton loading={googleLoading} onClick={() => { signInWithGoogle() }} variant="contained" color="info">
          <LoginRounded sx={{ mr: 2 }} /> Login With Google
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default SignIn
