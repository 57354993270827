import { AodRounded, RemoveRedEyeRounded } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Tooltip, Typography } from "@mui/material";
import { requestDetailsDataProps } from "./type";

type props = requestDetailsDataProps & { requestStatus: "CREATED" | "SUBMITTED" | "APPROVED" | "DISCARDED" }

const popupCenter = ({ url, title, w, h }: { url: string, title: string, w: number, h: number }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(url, title,
    `
    scrollbars=yes,
    resizable=no,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  newWindow?.focus();
}

const ListViewRequestIdDetails: React.FC<props> = ({
  status,
  prodPath,
  stagingPath,
  message,
  rowNo,
  requestStatus,
  fileSize,
  hostedUrl
  
}) => {

  return (
    
    <Card sx={{ display: 'flex',background:(fileSize && parseInt(fileSize) < 100)?"green":(fileSize && parseInt(fileSize) < 200)?"Yellow":"red" }}>
     {console.log("this is",fileSize)}
      <CardContent sx={{ flex: 1, display: 'flex' }}>
        <Tooltip title={`Row Number: ${rowNo}`}>
          {
          <Box>
            {!!rowNo && <Box component={'span'} sx={{
              backgroundColor: `#ffab00`,
              display: `inline-block`,
              lineHeight: `1`,
              padding: `4px 7px`,
              marginRight: `1em`,
              borderRadius: `4px`,
              color: `#fff`,
            }}
            >{rowNo}</Box>}
          </Box>}
        </Tooltip>
        {requestStatus === "APPROVED" ? <Typography variant="body2" color="text.primary" sx={{ wordBreak: 'break-all', paddingTop: `2px` }}>{`${prodPath}${" (Filesize "+fileSize+"kb)"}`}</Typography> : <Typography variant="body2" color="text.primary" sx={{ wordBreak: 'break-all' }}>{`${stagingPath}${" (Filesize "+fileSize+"kb)"}`}</Typography>}
        {!stagingPath && message && <Typography variant="body2" sx={(theme) => ({
          color: theme.palette.error.dark
        })}>{message}</Typography>}
      </CardContent>
      <CardActions sx={{ px: 2 }}> 
        {status === 'Failed' ? <Typography variant="body1" sx={(theme) => ({
          wordBreak: 'break-all',
          color: theme.palette.error.dark
        })}>{status}</Typography> : <>
         {requestStatus !== "APPROVED" && <Tooltip title="Staging Mobile View" >
            <Button  variant="contained" onClick={() => {
              popupCenter({
                url: `${process.env.REACT_APP_StagingPreviewSlug}${stagingPath}`,
                title: "AMP Mobile View",
                w: 390,
                h: 667
              })
            }} sx={{ mr: 1}}><AodRounded sx={{ mr: 1 }} /> Mobile </Button>
          </Tooltip>}
          {requestStatus !== "APPROVED" && <Button  variant="contained" target={`_blank`} href={`${process.env.REACT_APP_StagingPreviewSlug}${stagingPath}`} ><RemoveRedEyeRounded sx={{ mr: 1 }} /> Staging</Button>}
          {requestStatus === "APPROVED" && <Button variant="contained" target={`_blank`} href={`${hostedUrl}${prodPath}`} ><RemoveRedEyeRounded sx={{ mr: 1 }} /> Production</Button>}
        </>}
        
      </CardActions>
    </Card>
  )
}
export default ListViewRequestIdDetails