import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CenterScreenLayout, DashboardLayout } from "../Layouts";
import View, { Profile, RequestHistory, RequestIdDetails, Requests, SignIn, Templates, CustomTemplates, CustomRequests, CustomRequestIdDetails } from "../View";
import { useAuth } from "../_contexts";
import { CreateTemplate, CustomTempRequest, CustomTemplate, Reporting } from "../DynamicTemplate";

const Routing: React.FC = () => {
  const { isLoggedIn } = useAuth()
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* dashboard routing */}
          {isLoggedIn && (
            <Route path="/" element={<DashboardLayout />}>
              {/* <Route index element={<View pageName="Home" component={Home} />} /> */}
              <Route index element={<Navigate to={'/templates'} />} />
              <Route path="templates" element={<View pageName="Templates" component={Templates} />} />
              <Route path="requests" element={<View pageName="Requests" component={Requests} />} />
              <Route path="history" element={<View pageName="Request History" component={RequestHistory} />} />
              <Route path="createTemplate" element={<View pageName="Create Template" component={CreateTemplate} />} />
              <Route path="customTemplate" element={<View pageName="Custom Template" component={CustomTemplates} />} />
              <Route path="tempRequest" element={<View pageName="A/B Testing Request" component={CustomRequests} />} />
              <Route path="reporting" element={<View pageName="Reporting" component={Reporting} />} />
              <Route path="requests/:requestId" element={<RequestIdDetails />} />
              <Route path="customrequests/:requestId" element={<CustomRequestIdDetails />} />
              

              <Route path="profile" element={<View component={Profile} />} />

              {/* redirects */}
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="/register" element={<Navigate to="/" />} />
            </Route>
          )}

          {/* user access routing like: login/register/forgot */}
          {!isLoggedIn && (
            <Route path="/" element={<CenterScreenLayout />} >
              <Route index element={<Navigate to={'/login'} />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          )}

        </Routes>
      </BrowserRouter>
    </>
  )
}
export default Routing