import { CloseRounded, DocumentScannerRounded, UploadFileRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography,Checkbox,FormControlLabel, Autocomplete, Paper, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { BaseSyntheticEvent, useEffect, useState } from "react"
import { useUploadCsv, useTemplateList } from "./templates.api"
import { templateListProps } from "./types";
import { useAssistDoctorList } from "../Templates/templates.api"
const apiUrl = `${process.env.REACT_APP_AMP_API_SLUG}`

const Templates  = (props:any) => {
  const [searchData, setSearchData] = useState<templateListProps[]>();
  const { templateList, isLoading } = useTemplateList(true)

  const serchTemplate = (val: string) => {
    let newSerchData = templateList.filter(({ templateName }: templateListProps) => templateName.toLowerCase().includes(val.toLowerCase()));
    console.log({newSerchData})
    setSearchData(newSerchData)
  };

  useEffect(() => {
    templateList.length > 0 && setSearchData(templateList)
  }, [templateList])
  const [disableIds,setDisableIds]=useState(["62c5632b448fa3e2b3ae50ef","62e92fba06d440c191e46801","62e92fba06d440c191e46802","62e92fba06d440c191e46803","62e92fba06d440c191e46805",])
  return (
          <Autocomplete
            freeSolo
            id="template-search"
            style={{ width: "100%" }}
            options={templateList.map(({ templateName, _id }) => {
              return { label: templateName, id: _id }
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "100%" }}
                label="Select template for A/B Testing"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            onChange={(e, val: any) => {
              console.log({ val })
              if (!!val.id) {
                props.setSelectedValue(val.id)
              }
              !!val?.label ?
                serchTemplate(`${val?.label}`): serchTemplate("")
            }}
          />
  )
}

type props = {
  id: string,
  title: string,
  isOpen: boolean;
  handleClose: () => void;
  isAbTesting: boolean;
  hostedZoneData?: 
  {
    key:string,
    _id:string,
    value:string
  }[];
}

const FileUploadDialog: React.FC<props> = ({ id, title, isOpen, handleClose, isAbTesting, hostedZoneData }) => {
  const [fileData, setFileData] = useState<File | undefined>()
  const [exitPopup,setExitPopup]=useState(true)
  const [delayPopup,setDelayPopup]=useState(false)
  const [plTeamInput,setPlTeamInput]=useState(false)
  const [scrollPopup,setScrollPopup]=useState(false)
  const [doctorAutomateFlag,setDoctorAutomateFlag]=useState(false)
  const [clinicAutomateFlag,setClinicAutomateFlag]=useState(false)
  const [srNoAutomateFlag,setSrNoAutomateFlag]=useState(false)
  const [priceAutomateFlag,setPriceAutomateFlag]=useState(false)
  const [whatsAppAutomateFlag,setWhatsAppAutomateFlag]=useState(false)
  const [selectedTemplate, setSelectedValue] = useState("")
  const { response, isLoading, fetch } = useUploadCsv()
  const [domainName, setDomainName] = useState<any>({key:'',_id:'',value:''});

  const getFileInfo = (e: BaseSyntheticEvent) => {
    setFileData(e.target.files[0])
  }
  useEffect(() => {
    if (hostedZoneData && hostedZoneData.length > 0) {
      setDomainName(hostedZoneData[0]);
    }
  }, [hostedZoneData]);

  const formSubmit = () => {
    let params:any = {
      templateId: id,
      exitPopup:exitPopupId.includes(id)?!exitPopup:exitPopup,
      delayPopup:delayPopup,
      scrollPopup:scrollPopup,
      doctorAutomateFlag:doctorAutomateFlag,
      clinicAutomateFlag:clinicAutomateFlag,
      srNoAutomateFlag:srNoAutomateFlag,
      priceAutomateFlag:priceAutomateFlag,
      whatsAppAutomateFlag:whatsAppAutomateFlag,
      plTeamPopup:plTeamInput,
      hostedZone:domainName.value || "0"
    }
    if (selectedTemplate) {
      params =  {
        ...params,
        referenceTemplate: selectedTemplate
      }
    }
    fetch({
      data: {
        file: fileData
      },
      params
    })
  }
  // const [exitPopupId,setExitPopupId]=useState(['6376346c4c9f5a40c6ca42bb','6376346c4c9f5a40c6ca42bc','62de8da7a90381ebdc7a7df5','62de9528a90381ebdc7b50b5','646615872290519679c6dacf','646724875b1d7e3470764c74','64674b5e2290519679d86cab','6468adc3b93693710631c014'])
  const [exitPopupId,setExitPopupId]=useState(['6491b2bbd85af82e4497ec58','6493e26fd85af82e4408a236','6495944620c75cb55f92bfc4','649597f520c75cb55f939219','641d470babcb3082e0171e85','646615872290519679c6dacf','64674b5e2290519679d86cab','6376346c4c9f5a40c6ca42bb','64e09bb99c7e10f41000e5f6','64df435e4c3f08cae6c7ffd2','651690da4c3f08cae68be1e2','651bf4d1abe2bb7fe3c83ae2'])
  const [editableIds,setEditableIds]=useState(["637b23396971e62ee25cba12","637c79031ac5f43ca2fd95c7"])
  
  // const [editableIds,setEditableIds]=useState(["6376346c4c9f5a40c6ca42be","6376346c4c9f5a40c6ca42bf","6376346c4c9f5a40c6ca42c1","62822d11e10e41b088a10ab2","62822d11e10e41b088a10ab0","62822d11e10e41b088a10ab1","6376346c4c9f5a40c6ca42bd"])
  useEffect(() => {
    setFileData(undefined)
  }, [response])

  const handleDomainName = (event: any) =>{
    const filterValue = hostedZoneData?.find(item=>item.key===event.target.value)
    setDomainName(filterValue)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="file-upload-dialog-title"
      aria-describedby="file-upload-dialog-description"
      PaperProps={{
        sx: {
          overflowX: 'hidden'
        }
      }}
    >
      <DialogTitle id="file-upload-dialog-title" sx={{ fontSize: "2rem", borderBottom: "2px solid #696969" }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "400px" }}>
        <Box id="file-upload-dialog-description">
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", paddingTop: "1.5rem" }}>
            {
              fileData ?
                <>
                  <DocumentScannerRounded sx={{ fontSize: "5rem", color: "green", background: "white" }} />
                  <Typography component={'p'} style={{ fontSize: "1.5rem", margin: "10px", textAlign: 'center' }}>{fileData?.name}</Typography>
                </>
                :
                <>
                  <label style={{ cursor: 'pointer' }}>
                    <UploadFileRounded sx={{ fontSize: "5rem", margin: "0px", cursor: "pointer" }} />
                    <input type="file" accept=".csv, .xlsx" hidden onChange={getFileInfo} />
                  </label>
                  <Typography component={'p'} style={{ fontSize: "1.5rem", margin: "10px" }}>Upload Your File Here</Typography>
                </>
            }
          </Box>
        </Box>
      <FormControlLabel disabled={exitPopupId.includes(id)?false:true} sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked= {exitPopupId.includes(id)?!exitPopup:exitPopup} onChange={() => { setExitPopup(!exitPopup) }}/>} label="Include Exit Popup"/>
      <FormControlLabel sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={delayPopup} onChange={() => { setDelayPopup(!delayPopup) }}/>} label="Include Delay Popup"/>
      <FormControlLabel  sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={scrollPopup} onChange={() => { setScrollPopup(!scrollPopup) }}/>} label="Include Scroll Popup"/>
      <FormControlLabel disabled={editableIds.includes(id)?true:undefined}  sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={doctorAutomateFlag} onChange={() => { setDoctorAutomateFlag(!doctorAutomateFlag) }}/>} label="Include Doctor Automation"/>
      <FormControlLabel disabled sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={priceAutomateFlag} onChange={() => { setPriceAutomateFlag(!priceAutomateFlag) }}/>} label="Include Price Automation"/>
      <FormControlLabel sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={plTeamInput} onChange={() => { setPlTeamInput(!plTeamInput) }}/>} label="Include PL Team (Pristyn Assist)"/>
      {/* <FormControlLabel disabled={editableIds.includes(id)?true:undefined} sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={priceAutomateFlag} onChange={() => { setPriceAutomateFlag(!priceAutomateFlag) }}/>} label="Include Price Automation"/> */}
      {/* <FormControlLabel sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}} control={<Checkbox checked={doctorAutomateFlag} onChange={() => { setDoctorAutomateFlag(!doctorAutomateFlag) }}/>} label="Include Doctor Automation"/> */}
      <FormControlLabel  disabled sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={clinicAutomateFlag} onChange={() => { setClinicAutomateFlag(!clinicAutomateFlag) }}/>} label="Include Clinic Automation"/>
      <FormControlLabel   disabled sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px"}}control={<Checkbox checked={srNoAutomateFlag} onChange={() => { setSrNoAutomateFlag(!srNoAutomateFlag) }}/>} label="Include SR Number Automation"/>
      <FormControlLabel  sx={{ display: "flex",justifyContent:"left" ,paddingLeft:"20px",paddingBottom:"20px"}}control={<Checkbox checked={whatsAppAutomateFlag} onChange={() => { setWhatsAppAutomateFlag(!whatsAppAutomateFlag) }}/>} label="Include WhatsApp Automation"/>
      <FormControl style={{ width: "100%", paddingRight: "20px", marginLeft:'9px' }} size="small">
        <InputLabel id="demo-simple-select-label">Hosted Zone</InputLabel>
        <Select
          name="domainName"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={domainName.key}
          label="Hosted Zone"
          onChange={handleDomainName}
          renderValue={(selected) => (selected ? selected : "Hosted Zone")}
        >
          {hostedZoneData && hostedZoneData.length > 0 ? hostedZoneData.map((item: any) => {
            return (
              <MenuItem key={item._id} value={item.key}>{item.key}</MenuItem>
            )
          }):<MenuItem disabled>No options available</MenuItem>}
        </Select>
      </FormControl>
      </DialogContent>
      {isAbTesting && <FormControlLabel   disabled sx={{ width:"100%", display: "flex",justifyContent:"left" ,paddingLeft:"20px",paddingBottom:"20px", marginTop:'10px'}}control={<Templates setSelectedValue={setSelectedValue} isAbTesting={isAbTesting} />} label=""/>}
      <DialogActions sx={{ padding: "0px 15px 15px 0px",justifyContent:"center" }}>
      {/* <Templates /> */}
        {fileData && <Button type="button" variant="contained" color="error" onClick={() => { setFileData(undefined) }}>Reset</Button>}
        <LoadingButton loading={isLoading} variant="contained" color="success" autoFocus onClick={formSubmit} disabled={!fileData} >Submit</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
export default FileUploadDialog