import React, { useEffect, useState } from 'react'
import { useApiTemplateRequests } from '../View/CustomRequests/Requests.api'
import { useAuth } from "../_contexts";
import AccordionComponent from './Component/Accordion';
import { ListViewSkeletonList } from '../_skeletons';

const Reporting: React.FC = () => {
  const { requestData, isLoading, fetchData, StopApi } = useApiTemplateRequests()
  const [page, setpage] = useState(0)
  const [selectedValue, setSelectedValue] = useState<any>({})
  
  const getScrollTop = () => {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }

  const sensitizedData = (): any => {
    const data: any = {
      userId: [],
      templateId: [],
      status: [],
      tillDate: "",
      fromDate: "",
      requestId: [],
    };
    const { data: template, requestStatus } = selectedValue;

    data.templateId = template? [template] : [];
    data.status = requestStatus? [requestStatus] : [];
    return data;
  };

  const getDocumentHeight = () => {
    const body = document.body;
    const html = document.documentElement;

    return Math.max(
      body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight
    );
  };

  useEffect(() => {
    fetchData({
      page: 1,
      ...sensitizedData()
    })
  }, [selectedValue])


  // useEffect(()=>{
  //   if(requestData && requestData.length>0){
  //     let temp = requestData.filter(item=>item.status==='')
  //   }
  // },[requestData])


  window.onscroll = function () {
    console.log(page)
    if (getScrollTop() < getDocumentHeight() - window.innerHeight) return;
    if (!StopApi) {
      fetchData({ page: (page + 1), ...sensitizedData() })
      setpage(page + 1);
    }
  };

  const NoDataFound = ()=>{
    return (
      <div>   
          No Data Found
      </div>  
    )
  }
  return (
    <>
     { isLoading ?
          <ListViewSkeletonList />
          : requestData && requestData.length>0 && requestData.filter((item: any) =>
      ['APPROVED'].includes(item.status)
      // ['STOP', 'ABTESTED'].includes(item.status)
    ).map((data) => (
        <AccordionComponent
          id={data.requestId}
          templateName={data.templateComparison}
          status={data.status}
          createdBy={data.createdby}
          createdDate={data.creationDate}
        />
      ))}
    {requestData && requestData.length<1 && <NoDataFound/>}
    </>
  )
}

export default Reporting