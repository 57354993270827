import { Box, Typography } from "@mui/material"
import { Outlet } from "react-router-dom"
import pristynCareLogo from "../assets/img/pristyn-care-logo.svg"
import pristynCareLogo2000x2000 from "../assets/img/pristyn-care.logo2000x2000.jpg"
import { Img } from "../Components"
type props = {}

const CenterScreenLayout: React.FC<props> = () => {
  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: "center",
          alignItems: "flex-start",
          padding: ['10% 15px'],
          backgroundColor: '#f8f8fa',
          '&::before': {
            content: `''`,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `url(${pristynCareLogo2000x2000})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            filter: 'blur(85px)'
          }
        }}
      >
        <Box sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '8px',
          maxWidth: '500px',
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 5,
          paddingBottom: 5,
          boxShadow: '0px 1px 15px 1px rgb(0 0 0 / 20%)'
        }}>
          <Img src={pristynCareLogo} alt="Pristyn Care" />
          <Typography variant="h3" component="h1" sx={{ marginTop: 3, fontWeight: 700 }}> CMS Dashboard </Typography>
          <Outlet />
        </Box>
      </Box>
    </>
  )
}
export default CenterScreenLayout
