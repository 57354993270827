import { AccessTimeFilledRounded, Error } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useAuth } from "../../_contexts";
import { ListViewSkeletonList } from "../../_skeletons";
import { dateFormat } from "../../_utility";
import ListViewRequestIdDetails from "./ListViewRequestIdDetails";
import { useApiRequestApproval, useApiRequestDetail, useApiRequestSubmit } from "./RequestIdDetails.api";
import { requestDetailsDataProps } from "./type";


const RequestIdDetails: React.FC = () => { // remove default value
  const { setPageName } = useOutletContext<any>()
  const { requestId } = useParams()
  const { isRole } = useAuth()
  const [templateData, setTemplateData] = useState<requestDetailsDataProps[]>()
  const [requestStatus, setRequestStatus] = useState<string>()
  const [creationDate, setCreationDate] = useState<number>()
  const [successStatus, setSuccessStatus] = useState(true)
  const [failedStatus, setFailedStatus] = useState(true)
  const [failedCount, setFailedCount] = useState(0)
  const { isLoading, requestInfo, requestDataList } = useApiRequestDetail(`${requestId}`)
  const { fetchSubmitRequest, isSendingRequest } = useApiRequestSubmit(`${requestId}`, setRequestStatus)
  const { fetchApprovalRequest, isApprovingRequest } = useApiRequestApproval(`${requestId}`, setRequestStatus)

  function handleFilter() {
    requestDataList && setTemplateData(requestDataList.filter(item => {
      if ((successStatus && item.status === 'Success') || (failedStatus && item.status === 'Failed') || (failedStatus === successStatus)) {
        return true
      }
      return false
    }))
  }

  useEffect(() => {
    requestDataList && setTemplateData(requestDataList)
    if (requestInfo) {
      setCreationDate(requestInfo.creationDate)
      setRequestStatus(requestInfo.status)
      requestInfo.failureCount !== 0 && setFailedCount(requestInfo.failureCount)
      setPageName(`Requests : ${requestInfo.fileName} (${requestInfo.requestId})`)
    }
  }, [requestDataList, requestInfo])

  useEffect(() => {
    handleFilter()
  }, [successStatus, failedStatus])



  return (
    <Box>
      <Paper sx={{ py: [1], px: [2], mb: [3] }}>
        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item xs={12} md={`auto`}>
            <Typography component={'div'} variant="h6" color="text.primary" sx={{ wordBreak: 'break-all' }}>
              Created at : {!!creationDate && <Typography component={'em'} variant="h6" color="text.secondary"> {dateFormat(creationDate)} </Typography>}
            </Typography>
          </Grid>
          <Grid item xs={12} md={'auto'}>
            {requestInfo && requestInfo?.successCount > 0 && <FormControlLabel control={<Checkbox checked={successStatus} color="success" onChange={() => { setSuccessStatus(!successStatus) }} />} label="Success" />}
            {failedCount !== 0 && <FormControlLabel control={<Checkbox checked={failedStatus} color="error" onChange={() => { setFailedStatus(!failedStatus) }} />} label="Failed" />}
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              {(requestStatus === 'CREATED' || (isRole('PUBLISHER') && requestStatus === 'SUBMITTED')) && requestInfo && (
                <LoadingButton sx={{ marginRight: "20px" }} disabled={requestInfo?.successCount === 0} loading={isSendingRequest} onClick={() => { fetchSubmitRequest("DISCARDED") }} variant="contained" color="error" >
                  Discard
                </LoadingButton>
              )}

              {requestStatus === 'CREATED' && requestInfo && (
                <LoadingButton disabled={requestInfo?.successCount === 0} loading={isSendingRequest} onClick={() => { fetchSubmitRequest("SUBMITTED") }} variant="contained" color="secondary">
                  Send for release
                </LoadingButton>
              )}
              {isRole('PUBLISHER') && requestStatus === 'SUBMITTED' && requestInfo && (
                <LoadingButton disabled={requestInfo?.successCount === 0} loading={isApprovingRequest} onClick={() => { fetchApprovalRequest() }} variant="contained" color="success">
                  Release
                </LoadingButton>
              )}
            </Box>
            {!isRole('PUBLISHER') && requestStatus === 'SUBMITTED' && requestInfo && requestInfo?.successCount > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component={'div'}>Request sent. Wating for release</Typography>
                <AccessTimeFilledRounded color="warning" sx={{ fontSize: "1.5rem", marginLeft: 1 }} />
              </Box>
            )}
            {requestStatus === 'DISCARDED' && requestInfo && requestInfo?.successCount > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component={'div'}>Discarded</Typography>
                <Error color="error" sx={{ fontSize: "1.5rem", marginLeft: 1 }} />
              </Box>
            )}

            {isRole('PUBLISHER') && requestStatus === 'APPROVED' &&
              <Typography component={'div'} variant="h6" color="text.primary" sx={{ textAlign: 'right' }}>
                Created By: <Typography component={'em'} variant="h6" color="text.secondary"> {requestInfo && requestInfo.createdby}</Typography>
              </Typography>
            }
            {isRole('CREATOR') && requestStatus === 'APPROVED' &&
              <Typography component={'div'} variant="h6" color="text.primary" sx={{ textAlign: 'right' }}>
                Published By: <Typography component={'em'} variant="h6" color="text.secondary"> {requestInfo && requestInfo.lastUpdatedby}</Typography>
              </Typography>
            }
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        {isLoading ?
          <ListViewSkeletonList />
          :
          templateData && templateData.map((props: any, i: number) => (
            <Grid key={i} item lg={12}>
              <ListViewRequestIdDetails {...props} requestStatus={requestStatus} />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}
export default RequestIdDetails