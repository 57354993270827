import { useEffect, useState } from "react"
import { useWebContext } from "../../_contexts"
import { useAxios } from "../../_utility"
import { templateListProps, templateResponse } from "./types"

const apiUrl = `${process.env.REACT_APP_AMP_API_SLUG}`
const getTemplateUrl = `${apiUrl}/ampPages/abTesting/getTemplates`
const uploadCsvUrl = `${apiUrl}/ampPages/abTesting/creationRequest`
const downloadCsvUrl = `${apiUrl}/ampPages/abTesting/viewTemplate/`

export function useTemplateList(isOrg:any) {
  const [templateList, setTemplateList] = useState<templateListProps[]>([])
  const { response, error, isLoading } = useAxios({
    url: isOrg ? `${apiUrl}/ampPages/getTemplates` : getTemplateUrl,
    initFetch: true
  })

  useEffect(() => {
    const { result }: templateResponse = response || { status: '', description: '', result: { ampPagesTemplates: [] } }
    const { ampPagesTemplates } = result
    ampPagesTemplates.length !== 0 && setTemplateList(ampPagesTemplates.map((item) => {
      item.templateName = item.templateName || "Untitled"
      return item
    }))
    return () => {
      setTemplateList([])
    }
  }, [response, error])

  return { templateList, error, isLoading }
}

export function useUploadCsv() {
  const { response, isLoading, error, fetch } = useAxios({
    url: uploadCsvUrl,
    method: 'post'
  })
  const { notification } = useWebContext()
  useEffect(() => {
    if (response && response.status === "Success") {
      notification("File Uploaded Successfully", "success")
    }
    if (error && error.status === "Failed") {
      notification(error.description, "error")
    }

  }, [response, error])
  return { response, isLoading, fetch }
}

export function useCsvDownload(templateId: string) {
  const { response, isLoading, error, fetch } = useAxios({
    url: downloadCsvUrl + templateId,
  })

  return { response, error, isLoading, fetch }
}