import { ArrowDropDown, ArrowRight, PlaylistAddCheckRounded, SvgIconComponent, ViewCompactRounded } from "@mui/icons-material";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import AssistantIcon from '@mui/icons-material/Assistant';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { Box, Divider, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Img } from "..";
import LightLogo from "../../assets/img/pristyn-care-logo (Light).svg";
import Logo from "../../assets/img/pristyn-care-logo.svg";
import { useThemeContext } from "../../_contexts";


const SideNav: React.FC = () => {
  const { isDarkMode } = useThemeContext()

  return (
    <Box>
      <Toolbar >
        <Img src={isDarkMode ? LightLogo : Logo} alt="Pristyn Care" />
      </Toolbar>
      <Divider />
      <SimpleTreeView
        slots={{ collapseIcon: ArrowDropDown , expandIcon: ArrowRight  }}
      >
        {/* <AssideLink nodeId="1" to="/" text="Dashboard" Icon={DashboardRounded} /> */}
        <AssideLink nodeId="2" to="/templates" text="Templates" Icon={ViewCompactRounded} />
        <AssideLink nodeId="3" to="/requests" text="Requests" Icon={PlaylistAddCheckRounded} />
        <AssideLink nodeId="4" to="/createTemplate" text="Create A/B Template" Icon={NoteAltIcon} />
        <AssideLink nodeId="5" to="/customTemplate" text="A/B Template" Icon={AssistantIcon} />
        <AssideLink nodeId="6" to="/tempRequest" text="A/B Template Requests" Icon={RequestPageIcon} />
        <AssideLink nodeId="7" to="/reporting" text="A/B Reporting" Icon={AssessmentIcon} />
        {/* <AssideLink nodeId="4" to="/history" text="History" Icon={HistoryRounded} /> */}
      </SimpleTreeView>
    </Box>
  );
};

export default SideNav;

type assideLinkProps = {
  nodeId: string,
  to: string,
  text: string,
  Icon?: SvgIconComponent
}
const AssideLink: React.FC<assideLinkProps> = ({ nodeId, to, text, Icon }) => {
  const navigate = useNavigate();
  return (
    <TreeItem itemId={nodeId} label={
      <Box sx={{display: 'flex', alignItems: 'center', py: 1, fontSize: '1em'}}>
        {Icon && <Icon sx={{mr: 1}} />} {text}
      </Box>
    } onClick={() => { navigate(to) }} sx={{mt: 0.5}} />
  )
}