import { Autocomplete, Box, Grid, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { TemplateSkeletonList } from "../../_skeletons";
import { useTemplateList } from "./templates.api";
import TemplateViewItem from "./TemplateViewItem";
import { templateListProps } from "./types";
import './template.css';

const Templates: React.FC = () => {
  const [searchData, setSearchData] = useState<templateListProps[]>();
  const { templateList, isLoading } = useTemplateList(false)

  const serchTemplate = (val: string) => {
    let newSerchData = templateList.filter(({ templateName }: templateListProps) => templateName.toLowerCase().includes(val.toLowerCase()));
    setSearchData(newSerchData)
  };

  useEffect(() => {
    templateList.length > 0 && setSearchData(templateList)
  }, [templateList])
  const [disableIds,setDisableIds]=useState(["62c5632b448fa3e2b3ae50ef","62e92fba06d440c191e46801","62e92fba06d440c191e46802","62e92fba06d440c191e46803","62e92fba06d440c191e46805",])
  return (
    <Box sx={{ mx: ['auto'] }}>
      <Box sx={{ mx: ['auto'], marginBottom: "2rem" }}>
        <Paper sx={{ p: 2 }}>
          <Autocomplete
            freeSolo
            id="template-search"
            options={templateList.map(({ templateName, _id }) => {
              return { label: templateName, id: _id }
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Template"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            onChange={(e, val: any) => {
              !!val?.label ? serchTemplate(`${val?.label}`) : serchTemplate("")
            }}
          />
        </Paper>
      </Box>

      <Grid container spacing={5}>
        {isLoading ? <TemplateSkeletonList /> :
          searchData && searchData.map((props, i) => (
            <Grid key={i} item xs={12} md={6} lg={4} >
              <TemplateViewItem {...props} />
            </Grid>
            
          ))}
      </Grid>
    </Box>
  )
}
export default Templates