import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuth } from '../../_contexts';
import { dateFormat } from '../../_utility';
import { CircularProgress } from '@mui/material';

interface AccordionComponentProps {
  templateName: string;
  status: string;
  createdBy: string;
  createdDate: string;
}
interface DataEntry {
  _id: {
      timestamp: number;
      counter: number;
      date: number;
      time: number;
      machineIdentifier: number;
      processIdentifier: number;
      timeSecond: number;
  };
  event_template_name: string;
  pageUrl: string;
  reference_Id1: string;
  creationDate: number;
  data: {
      call_click_count: number;
      form_submit_count: number;
      PageView_count: number;
  };
  lastUpdateDate: number;
}

interface MergedDataEntry {
  _id: {
      timestamp: number;
      counter: number;
      date: number;
      time: number;
      machineIdentifier: number;
      processIdentifier: number;
      timeSecond: number;
  };
  event_template_name: string;
  pageUrl: string;
  reference_Id1: string;
  creationDate: number;
  data: {
      [key: string]: {
          call_click_count: number;
          form_submit_count: number;
          PageView_count: number;
      };
  };
  lastUpdateDate: number;
}

const mergeData = (data: DataEntry[]): MergedDataEntry[] => {
  const mergedData: { [key: string]: MergedDataEntry } = {};

  data.forEach((entry) => {
      const { pageUrl, event_template_name, data: entryData, ...rest } = entry;
      if (pageUrl in mergedData) {
          mergedData[pageUrl].data[event_template_name] = entryData;
      } else {
          mergedData[pageUrl] = {
              ...rest,
              event_template_name,
              pageUrl,
              reference_Id1: entry.reference_Id1,
              creationDate: entry.creationDate,
              data: { [event_template_name]: entryData },
              lastUpdateDate: entry.lastUpdateDate
          };
      }
  });

  return Object.values(mergedData);
};

const AccordionComponent = (props:any) => {
  const [responseData, setResponseData] = useState<MergedDataEntry[]>([]);
  const { accessToken } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
 
  const TableHtml = ({ dataArray }: { dataArray: any[] }) => {
    if(isLoading){
      return <div style={{textAlign:'center'}}><CircularProgress/></div>
    }
    if (!dataArray.length) {
      return <div style={{border:'1px dashed black', textAlign:'center', padding:'10px'}}>No data available</div>;
    }

  
    const formatEventName = (eventName: string): string => {
      const parts: string[] = eventName.split('_');
      return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
    };
   
    let countKeysArray:any[] = [];
    dataArray.forEach(element => {
      for (let key in element.data) {
          if (element.data.hasOwnProperty(key)) {
              for (let nestedKey in element.data[key]) {
                  if (element.data[key].hasOwnProperty(nestedKey)) {
                      if (nestedKey.endsWith('_count')) {
                          countKeysArray.push(nestedKey.replace('_count',''));
                      }
                  }
              }
          }
      }
    });

    const headers =  Array.from(new Set(countKeysArray));
    const tableHeaders = ['Links','Versions', ...headers];
    const dataRows = dataArray.map((item: any, index: number) => {
      const eventTemplateNames = Object.keys(item.data)

     let row:any[] = []

      const linkCell = (
        <td rowSpan={eventTemplateNames?.length} key={`link-${index}`} style={{ border: '1px solid black', padding:'5px 10px', textAlign:'start' }}>
          <a href={item.pageUrl} target="_blank" rel="noopener noreferrer">
            {item.pageUrl.split("/gp/")[1]}
          </a>
        </td>
      );

          eventTemplateNames.forEach((element, elementIndex)=>{
                if(elementIndex>0){
                    row.push(
                      <tr key={`key-${index}`} style={{backgroundColor: index%2!==0?'rgba(25, 118, 210, 0.08)':'fff'}}>
                        <td style={{border:'1px solid black', textAlign:'center', padding:'5px'}}>{decodeURIComponent(element)}</td>
                      {
                        headers.map((value, valueIndex)=>{
                          const displayValue = item.data[`${element}`] && item.data[`${element}`][`${value}_count`] ? item.data[`${element}`][`${value}_count`] : 0;
                          return (
                            <td  key={`value-${valueIndex}`} style={{border:"1px solid black", textAlign:'center'}}>{displayValue}</td>
                          )
                        })
                      }
                      </tr>
                    )
                }
                else{
                  row.push(
                    <tr key={`key-${index}`} style={{backgroundColor: index%2!==0?'rgba(25, 118, 210, 0.08)':'fff'}}>
                      {linkCell}
                      <td style={{border:'1px solid black', textAlign:'center', padding:'5px'}}>{decodeURIComponent(element)}</td>
                    {
                      headers.map((value, valueIndex)=>{
                        const displayValue = item.data[`${element}`] && item.data[`${element}`][`${value}_count`] ? item.data[`${element}`][`${value}_count`] : 0;
                        return (
                          <td  key={`value-${valueIndex}`} style={{border:"1px solid black", textAlign:'center'}}>{displayValue}</td>
                        )
                      })
                    }
                    </tr>
                  )
                }

      })

     return row
    });

    return (
      <table style={{ width: '100%', borderCollapse:'collapse', fontSize:'14px'}}>
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
                <th key={index} style={{backgroundColor:'#E7EBF0', border:'1px solid black',padding:'2px  5px'}}>
                  {formatEventName(header)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>{dataRows}</tbody>
      </table>
    );
  };
  
  

  const fetchData = async (event :any) => {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    };

    try {
      const apiUrl = `${process.env.REACT_APP_AMP_API_SLUG}`
      setIsLoading(true)
      const response = await fetch(`${apiUrl}/ampPages/abTesting/getTrackingData?requestId=${props.id}`, requestOptions);
      const data = await response.json();
      const mergedData = mergeData(data.result);
      setIsLoading(false)
      setResponseData(mergedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  return (
    <Accordion sx={{marginBottom:'2rem'}}>
       <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        onClick={fetchData}
        sx={{'& .MuiAccordionSummary-content.Mui-expanded': {
         margin:'20px 0 0 !important'
        },}}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            width:'95%',
            paddingLeft:'1rem',
            fontSize:'14px'
          }}
        >
          <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
            <span style={{marginRight:'16px'}}>Template Name : {props.templateName}</span>
            <span style={{marginRight:'16px'}}>Status: {props.status}</span>
          </div>
          <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
            <span style={{marginRight:'16px'}}>Created By : {props.createdBy}</span>
            <span style={{marginRight:'16px'}}>Created Date: {dateFormat(props.createdDate)}</span>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
          <TableHtml dataArray={responseData}/>
      </AccordionDetails> 
    </Accordion>
  );
};

export default AccordionComponent;