import { CheckBoxRounded, ContentCopyRounded, Error, WatchLaterRounded } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Card, CardActionArea, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useWebContext } from "../../_contexts";
import { dateFormat } from "../../_utility";
import { useDownloadCsv } from "./Requests.api";
import { requestsDataProps } from "./types";


const RequestItemsView: React.FC<requestsDataProps> = ({ fileName, requestId, creationDate, createdby, status, successCount, failureCount, lastUpdateDate, lastUpdatedby }) => {
  const {fetch} = useDownloadCsv({
    requestId: requestId,
    fileName:fileName
  })
  const { notification } = useWebContext() 
  var UpdatedText = status === "DISCARDED" ? "Discarded" : "Approved"
  return (
    <Card data-component="RequestItemsView" sx={{ position: 'relative', minHeight: "240px" }}>
      <CardActionArea sx={(theme => ({
        backgroundColor: (theme.palette.mode === 'light' ? "#f5f5f5" : "#646464")
      }))} >
        <Link to={`/customrequests/${requestId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <CardContent sx={{ pb: 0 }}>
            <Typography variant="h6" sx={{ cursor: "pointer" }} component="div">
              {fileName}
            </Typography>
            <Box sx={{ mb: 0, display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ mb: 0 }} color="text.secondary">Created By: {createdby}</Typography>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary">Created At: {dateFormat(creationDate)}</Typography>
            </Box>
            {lastUpdatedby && status !== "SUBMITTED" && status !== "CREATED" &&
              <Box sx={{ mb: 0, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ mb: 0 }} color="text.secondary">{UpdatedText} By: {lastUpdatedby}</Typography>
                <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary">{UpdatedText} At: {dateFormat(lastUpdateDate)}</Typography>
              </Box>
            }
          </CardContent>
        </Link>
      </CardActionArea>

      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <Tooltip title="Download CSV">
          <IconButton onClick={() => {
           fetch()
          }}>

            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy Request ID">
          <IconButton onClick={() => {
            if (!!navigator.clipboard) {
              navigator.clipboard.writeText(requestId)
              notification("Request Id Copied")
            }
            else {
              notification("Click to Copy only work for HTTPS", 'error')
            }
          }}>

            <ContentCopyRounded />
          </IconButton>
        </Tooltip>
      </Box>

      <CardContent sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'flex-end'
      }}>
        <Box sx={{ minHeight: "2rem" }}>
          <Typography variant="body2" sx={{ color: "green" }}>
            {successCount} Updated URL's.
          </Typography>
          {failureCount !== 0 &&
            <Typography variant="body2" sx={{ color: "red" }}>
              {failureCount} failed URL's.
            </Typography>}
          {!!lastUpdateDate &&
            <Typography variant="body2" color='text.secondary'>
              Last Update: {dateFormat(lastUpdateDate)}
            </Typography>
          }
        </Box>
        <Box sx={{ paddingLeft: 1, maxWidth: '45%' }}>
          {status === "APPROVED" &&
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle2" component={'div'}>Released</Typography> <CheckBoxRounded sx={{ color: "green", fontSize: "2rem", marginLeft: 1 }} />
            </Box>
          }
          {status === "SUBMITTED" &&
            <Box sx={{ textAlign: 'center' }}>
              <WatchLaterRounded sx={{ color: "orange", fontSize: "1.5rem" }} />
              <Typography variant="body2" component={'div'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title="Request sent. Wating for release">Request sent. Wating for release</Typography>
            </Box>}
          {status === "DISCARDED" &&
            <Box sx={{ textAlign: 'center' }}>
              <Error sx={{ color: "red", fontSize: "1.5rem" }} />
              <Typography variant="body2" component={'div'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title="Discarded">Discarded</Typography>
            </Box>}


        </Box>
      </CardContent>
    </Card>
  )
}
export default RequestItemsView