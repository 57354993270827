import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useWebContext } from "../../_contexts";
import { StatusResponseProps, TemplateResultProps } from "./types";

type props = {
  suggestion: suggestionProps[],
  templates: TemplateResultProps[],
  status: StatusResponseProps[],
  callback?: (a: any, b: any, c: any, d: any, e: any) => any
}

export type suggestionProps = {
  title: string,
  value: string | number,
  tag?: string
}

export default function RequestFilter({ suggestion, templates, status, callback }: props) {
  const [value, setValue] = useState<suggestionProps[]>([]);
  const [startDate, setstartDate] = useState<string>((() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date.toISOString().split('T')[0]
  })());
  const [EndDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [template, setTemplate] = useState<string>("");
  const [requestStatus, setrequestStatus] = useState<string>("");
  const { notification } = useWebContext()

  const handleChange = (event: any) => {
    if (event.target.name === "from") {
      setstartDate(event.target.value)
    } else if (event.target.name === "to") {
      setEndDate(event.target.value)
    } else if (event.target.name === "template") { setTemplate(event.target.value) }
    else if (event.target.name === "requestStatus") { setrequestStatus(event.target.value) }
  };

  const SendData = () => {
    if (EndDate < startDate) {
      notification("From date cannot be greater than to date", 'error')
    }
    else {
      callback && callback(value, startDate, EndDate, template, requestStatus)
    }
  }
  const ResetFilter = () => {
    setValue([]);
    setTemplate("");
    setrequestStatus("");
    setstartDate((() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date.toISOString().split('T')[0]
    })())
    setEndDate(new Date().toISOString().split('T')[0])
    let userId: any = []
    let templateId: any = ""
    let status: any = ""
    let tillDate: any = new Date().toISOString().split('T')[0]
    let fromDate: any = (() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date.toISOString().split('T')[0]
    })()
    callback && callback(userId, fromDate, tillDate, templateId, status)
  }

  return (
    <Box sx={{ display: ['block', 'flex'], }}>
      <Autocomplete
        style={{ width: "300px", paddingRight: "20px" }}
        multiple
        size="small"
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...newValue
          ]);
        }}
        onKeyUp={(event: any) => {
          if (event.key === 'Enter') {
            event.defaultMuiPrevented = true;
            let val = `${event?.target?.value}`.trim()
            let newItem: suggestionProps = { title: val, value: val, tag: 'notMatch' }
            !!val && setValue([...value, newItem])
            event.target.blur()
          }
        }}
        options={suggestion}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Search" placeholder="Search" />
        )}
      />
      <TextField
        id="from"
        style={{ width: "200px", paddingRight: "20px" }}
        label="From"
        size="small"
        type="date"
        name="from"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          value: startDate,
          max: new Date().toISOString().split('T')[0],
        }}
        onChange={handleChange}
      />
      <TextField
        id="to" style={{ width: "200px", paddingRight: "20px" }}
        label="To"
        type="date"
        name="to"
        size="small"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          value: EndDate,
          max: new Date().toISOString().split('T')[0],
        }}
        onChange={handleChange}

      />
      <FormControl style={{ width: "200px", paddingRight: "20px" }} size="small">
        <InputLabel id="demo-simple-select-label">Template</InputLabel>
        <Select
          name="template"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={template}
          label="Template"
          onChange={handleChange}
        >
          {templates && templates.map((item, index) => {
            return (
              <MenuItem value={item.templateId}>{item.templateName}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <FormControl style={{ width: "200px", paddingRight: "20px" }} size="small">
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          name="requestStatus"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={requestStatus}
          label="Status"
          onChange={handleChange}
        >
          {status && status.map((item, index) => {
            return (
              <MenuItem value={item}>{item}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl style={{ paddingRight: "25px", justifyContent: 'center', alignItems: 'center' }} size="small">
        <Button variant="outlined" onClick={ResetFilter} >Reset</Button>
      </FormControl>
      <FormControl style={{ paddingRight: "20px", paddingLeft: "5px", justifyContent: 'center', alignItems: 'center' }} size="small">
        <Button variant="contained" onClick={SendData}>Apply</Button>
      </FormControl>
    </Box>
  );
}
