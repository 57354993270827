import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import FormComponent from '../Component/Form';
import ButtonComponent from '../Component/Button';
import BannerComponent from '../Component/Banner';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { CSV_CONSTANT, useUpdateTemplate, useUploadCsv } from './createTemplate.api';
import HeaderComponent from '../Component/Header';
import FooterComponent from '../Component/Footer';
import './createTemplate.scss';

interface Component {
  type: string;
  id: number;
  position: { x: number; y: number };
}

const CreateTemplate: React.FC = () => {
  const [components, setComponents] = useState<Component[]>([]);
  const [storeHtml, setStoreHtml] = useState<String | undefined>('');
  const [templateName, setTemplateName] = useState<String | undefined>('');
  const {response, isLoading, fetch } = useUploadCsv()
  const {response:uploadTempRes, isLoading:uploadTempLoading, fetch:uploadTempFetch } = useUpdateTemplate()

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const componentType = e.dataTransfer.getData('componentType');
    const xPos = e.clientX - e.currentTarget.getBoundingClientRect().left;
    const yPos = e.clientY - e.currentTarget.getBoundingClientRect().top;
    const newComponent: Component = {
      type: componentType,
      id: Date.now(),      
      position: { x: xPos-220, y: yPos },
    };
    setComponents([...components, newComponent]);
  };

  const handleDrag = (index: number, newPosition: { x: number; y: number }) => {
    const updatedComponents = [...components];
    updatedComponents[index].position = newPosition;
    setComponents(updatedComponents);
  };

  const removeComponent = (id: number) => {
    console.log({id});
    
    const updatedComponents = components.filter((component) => component.id !== id);
    setComponents(updatedComponents);
  };

  const handleSubmit = () =>{
    if(!!templateName){
      fetch({
        data: {
          templateName:`${templateName}${new Date().valueOf()}`,
          row:CSV_CONSTANT,
        }
      })
    }
  }

  useEffect(() => {  
    const pageView = document.querySelector('#pageViewId');
    const templateCloseIcon = pageView?.querySelectorAll(".templateCloseIcon")as NodeListOf<HTMLElement>
    templateCloseIcon && templateCloseIcon?.forEach(item=>item.remove())
    
    if(response?.result[0]?.templateId){
      const templateBorder = pageView?.querySelector<HTMLElement>("#rightPageViewContainerId")
      if(templateBorder){
        templateBorder.style.border='unset';
        templateBorder.style.width='unset';
      }
      
      let head = `<html><head>
  <meta content="width=device-width,initial-scale=1,shrink-to-fit=no" name="viewport">
  </head><body style = "margin:0">`

      uploadTempFetch({
        data:{
          templateId:response?.result[0]?.templateId,
          htmlTemplate: head + `${pageView?.innerHTML}` + `</body></html>`
        }
      })
      setTemplateName('')
    }
  }, [response?.result[0]?.templateId])

  const handleTemplateName = (event: any) =>{
      setTemplateName(event.target.value)
  }
  
  return (
    <Box className="createTemplateContainer">
      <Grid container>
        <Grid item xs={5} sx={{ background: 'white', marginRight: 2 }} padding={2}>
          <Typography variant="h5" component="h5">Component Toolkit</Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <div className="toolkit" draggable="true" onDragStart={(e) => e.dataTransfer.setData('componentType', 'Header')}>
            <HeaderComponent dontShow={true} width={'100%'}/>
          </div>
          <Divider sx={{ margin: '20px 0' }} />
          <div className="toolkit" draggable="true" onDragStart={(e) => e.dataTransfer.setData('componentType', 'Banner')}>
            <BannerComponent dontShow={true} />
          </div>
          <Divider sx={{ margin: '20px 0' }} />
          <div className="toolkit" draggable="true" onDragStart={(e) => e.dataTransfer.setData('componentType', 'Button')}>
            <ButtonComponent dontShow={true} />
          </div>
          <Divider sx={{ margin: '20px 0' }} />
          <div className="toolkit" draggable="true" onDragStart={(e) => e.dataTransfer.setData('componentType', 'Form')}>
            <FormComponent dontShow={true} />
          </div>
          <Divider sx={{ margin: '20px 0' }} />
          <div className="toolkit" draggable="true" onDragStart={(e) => e.dataTransfer.setData('componentType', 'Footer')}>
            <FooterComponent dontShow={true} />
          </div>
        </Grid>
        <Grid xs item>
        <TextField value={templateName} onChange={handleTemplateName} id="templateNameId" label="Template Name" variant="outlined" size='small'></TextField>
        <Button onClick={handleSubmit} variant="contained" sx={{ml:2}} disabled={templateName && templateName?.length>4?false:true}>Submit</Button>
        <Grid
          item
          xs
          sx={{ background: 'white', position: 'relative', marginTop:'10px', display:'flex', justifyContent:'center' }}
          height={'100%'}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          id="pageViewId"
        >
          <div id='rightPageViewContainerId'>
          {components.map((component, index) => (
            <Draggable
              key={component.id}
              defaultPosition={{ x: component.position.x, y: component.position.y }}
              onStop={(e: DraggableEvent, data: DraggableData) =>
                handleDrag(index, { x: data.x, y: data.y })
              }
              bounds="#pageViewId"
            >
              <div style={{ position: 'absolute'}}>
                <div style={{ position: 'relative'}}>
                  {component.type === 'Header' && <HeaderComponent removeComponent={() => removeComponent(component.id)} width={'400px'}/>}
                  {component.type === 'Banner' && <BannerComponent  removeComponent={() => removeComponent(component.id)} width={'360px'}/>}
                  {component.type === 'Button' && <ButtonComponent removeComponent={() => removeComponent(component.id)} width={'360px'}/>}
                  {component.type === 'Form' && <FormComponent removeComponent={() => removeComponent(component.id)} width={'360px'}/>}
                  {component.type === 'Footer' && <FooterComponent  removeComponent={() => removeComponent(component.id)} width={'400px'}/>}
                </div>
              </div>
            </Draggable>
          ))}
          </div>
        </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateTemplate;
