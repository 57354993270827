import { Box, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { TemplateSkeletonList } from "../../_skeletons/index";
import RequestFilter, { suggestionProps } from "./RequestFilter";
import RequestItemsView from "./RequestItemsView";
import { useApiTemplateRequests, useGetSuggestion } from "./Requests.api";
import { requestsDataProps } from "./types";
import CircularProgress from '@mui/material/CircularProgress';

const CustomRequests: React.FC = () => {
  const { requestData, isLoading, fetchData, StopApi } = useApiTemplateRequests()
  const { suggestionResponse, TemplateResponse, StatusResponse } = useGetSuggestion()
  const [autoCompleteSuggestion, setAutoCompleteSuggestion] = useState<suggestionProps[]>([])
  const [Template, setTemplate] = useState<any[]>([])
  const [Status, setStatus] = useState<any[]>([])
  const [page, setpage] = useState(0)
  const [selectedValue, setSelectedValue] = useState<any>({})

  const sensitizedData = () => {
    const data: any = {
      userId: [],
      templateId: [],
      status: [],
      tillDate: "",
      fromDate: "",
      requestId: []
    }
    const { data: searchField, from, to, template, requestStatus } = selectedValue
    searchField && searchField.length > 0 && searchField.forEach((element: any) => {
      data[`userId`].push(element.value)
    });
    data[`templateId`] = template ? [template] : []
    data[`tillDate`] = formatDate(to ? to : new Date().toISOString().split('T')[0])
    data[`fromDate`] = formatDate(from ? from : (() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date.toISOString().split('T')[0]
    })())
    data[`status`] = requestStatus ? [requestStatus] : []
    return data
  }
  const formatDate = (input: string) => {
    if (input) {
      var datePart = input.match(/\d+/g)
      if (datePart) {
        let year = datePart[0]
        let month = datePart[1]
        let day = datePart[2]
        return day + '/' + month + '/' + year
      }
    }
  }
  
  window.onscroll = function () {
    console.log(page)
    if (getScrollTop() < getDocumentHeight() - window.innerHeight) return;
    if (!StopApi) {
      fetchData({ page: (page + 1), ...sensitizedData() })
      setpage(page + 1);
    }

  };

  const getScrollTop = () => {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  }

  const getDocumentHeight = () => {
    const body = document.body;
    const html = document.documentElement;

    return Math.max(
      body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight
    );
  };

  useEffect(() => {
    fetchData({
      page: 1,
      ...sensitizedData()
    })
  }, [selectedValue])

  useEffect(() => {
    if (suggestionResponse) {
      const { users } = suggestionResponse
      setAutoCompleteSuggestion([
        ...(users.map(({ userId, username }) => {
          return { title: `${username}`, value: userId, tag: 'userId' }
        })),
      ])
    }

    if (TemplateResponse && TemplateResponse !== undefined) {
      setTemplate(TemplateResponse)
    }
    if (StatusResponse && StatusResponse !== undefined) {
      setStatus(StatusResponse)
    }
  }, [suggestionResponse, TemplateResponse, StatusResponse])


  return (
    <Box>
      <Box sx={{ mx: ['auto'], marginBottom: "2rem" }}>
        <Paper sx={{ px: 2, py: 2 }}>
          <RequestFilter templates={Template} status={Status} suggestion={autoCompleteSuggestion} callback={(data, from, to, template, requestStatus) => {
            setpage(0)
            setSelectedValue({ data, to, from, template, requestStatus })}} />
        </Paper>
      </Box>
      <Grid container spacing={5}>
        {requestData && requestData.map((data: requestsDataProps, i) => (
          <Grid key={i} item xs={12} md={6} >
            <RequestItemsView {...data} />
          </Grid>
        ))}
        {isLoading && requestData.length<1 && <TemplateSkeletonList />}
        
      </Grid>
      {isLoading && requestData.length>0  && <Box sx={{
    alignItems: 'center',
    height: '7rem',
    justifyContent: 'center',
    display: 'flex',
}}> <CircularProgress sx={{    width: '50px',
  height: '50px'}} /></Box>}
    </Box>
  )
}
export default CustomRequests