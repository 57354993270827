
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ButtonComponent = (props:any) => {
  const [hoverState, setHoverState] = useState(false)

  const handleHover = (event:any)=>{
    event.stopPropagation()
    setHoverState(true)
  }
  const handleHoverOut = (event:any)=>{
    event.stopPropagation()
    setHoverState(false)
  }
  
  return (
    <div onMouseOver={(e) => handleHover(e)} onMouseOut={(e) => handleHoverOut(e)}>
      {<CloseIcon
          onMouseOver={(e) => handleHover(e)}
        className='templateCloseIcon'
          style={{
            position:'absolute',
            top: '-10px',
            display: `${!hoverState || props.dontShow ? "none" : "block"}`,
            right: '-10px',
            cursor: 'pointer',
            background: '#fff',
            borderRadius: '50%',
            padding: '3px',
            width:'25px',
            height:'25px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',                     
          }}
          onClick={() => props.removeComponent()}
          />}
            <button style={{background:'#ff8300',
      padding: '10px',fontSize:'16px', display:'flex',
      alignItems: 'center',
      gap: '10px',
      fontWeight: 700,
      justifyContent: 'center', lineHeight: '35px',
      width: props?.width || '100%', border:'none', color:'#00263e', borderRadius:'2.75em'}}>
        <svg fill="#00263e" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
	 width="30px" height="30px" viewBox="0 0 414.937 414.937"
	 >
<g>
	<path d="M159.138,256.452c37.217,36.944,80.295,72.236,97.207,55.195c24.215-24.392,39.12-45.614,92.854-2.761
		c53.734,42.874,12.696,71.727-10.757,95.363c-27.064,27.269-128.432,1.911-228.909-97.804C9.062,206.71-17.07,105.54,10.014,78.258
		c23.46-23.637,52.006-64.879,95.254-11.458c43.269,53.394,22.161,68.462-2.054,92.861
		C86.31,176.695,121.915,219.501,159.138,256.452z M213.104,80.203c0,0-11.227-1.754-19.088,6.113
		c-8.092,8.092-8.445,22.032,0.082,30.552c5.039,5.039,12.145,6.113,12.145,6.113c13.852,2.598,34.728,6.997,56.944,29.206
		c22.209,22.208,26.608,43.084,29.206,56.943c0,0,1.074,7.106,6.113,12.145c8.521,8.521,22.46,8.174,30.552,0.082
		c7.861-7.86,6.113-19.087,6.113-19.087c-4.399-28.057-17.999-57.365-41.351-80.716C270.462,98.203,241.153,84.609,213.104,80.203z
		 M318.415,96.958c40.719,40.719,58.079,86.932,52.428,124.379c0,0-1.972,11.859,5.773,19.604
		c8.718,8.718,22.535,8.215,30.695,0.062c5.243-5.243,6.385-13.777,6.385-13.777c4.672-32.361-1.203-97.464-64.647-160.901
		C285.605,2.887,220.509-2.988,188.147,1.677c0,0-8.527,1.136-13.777,6.385c-8.16,8.16-8.656,21.978,0.061,30.695
		c7.746,7.746,19.604,5.773,19.604,5.773C231.484,38.879,277.696,56.24,318.415,96.958z"/>
</g>
</svg>
regx_hero_cta
    </button>
    </div>

  );
};

export default ButtonComponent;
