import View from './View'
export { default as Home } from './Home/Home'
export { default as Profile } from './Profile/Profile'
export { RequestHistory } from './RequestHistory'
export { RequestIdDetails } from './RequestIdDetails'
export { default as Requests } from './Requests/Requests'
export { default as Templates } from './Templates/Templates'
export { default as SignIn } from './UserAccess/SignIn'
export { default as CustomTemplates } from "./CustomTemplates/Templates"
export { default as CustomRequests } from "./CustomRequests/Requests"
export { CustomRequestIdDetails } from "./CustomRequestIdDetails/"



export default View
