import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const BannerComponent = (props: any) => {
  const [hoverState, setHoverState] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [bannerStyle, setBannerStyle] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);

  const handleHover = (event:any) => {
    event.stopPropagation()
    setHoverState(true);
  };

  const handleHoverOut = (event:any) => {
    event.stopPropagation()
    setHoverState(false);
  };

  const handleEditClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (!editMode) {
      setBannerStyle({});
    }
  };

  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBannerStyle({ ...bannerStyle, [name]: value });
  };

  const applyStyles = () => {
    setEditMode(true);
    setOpenModal(false);
  };

  return (
    <div onMouseOver={handleHover} onMouseOut={handleHoverOut}>
      <div className="templateCloseIcon">
        <EditIcon
          className="templateEditIcon"
          onMouseOver={handleHover}
          onClick={handleEditClick}
          style={{
            position: 'absolute',
            top: '-10px',
            display: `${!hoverState || props.dontShow ? 'none' : 'block'}`,
            right: '30px',
            cursor: 'pointer',
            background: '#fff',
            borderRadius: '50%',
            padding: '3px',
            width: '25px',
            height: '25px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
          }}
        />
        <CloseIcon
          onMouseOver={handleHover}
          style={{
            position: 'absolute',
            top: '-10px',
            display: `${!hoverState || props.dontShow ? 'none' : 'block'}`,
            right: '-10px',
            cursor: 'pointer',
            background: '#fff',
            borderRadius: '50%',
            padding: '3px',
            width: '25px',
            height: '25px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
          }}
          onClick={() => props.removeComponent()}
        />
      </div>
      <div
        style={{
          backgroundColor: '#f0f0f0',
          color: '#000',
          padding: '40px 0 0',
          width: bannerStyle?.width || props?.width || '100%',
          display: 'flex',
          justifyContent: 'space-around',
          height: bannerStyle.height || 'max-content',
        }}
      >
        <div>
        <h3 style={{textAlign:'start', margin:'unset', marginLeft:'14px', marginBottom:'5px'}}>regx_hero_title</h3>
      <ul style={{paddingLeft:'30px', margin:'unset'}}>
          <li key={'index-1'}>
            <span className="semicircleorange"></span> <span>regx_hero_list_item_1</span>
          </li>
          <li key={'index-2'}>
            <span className="semicircleorange"></span> <span>regx_hero_list_item_2</span>
          </li>
          <li key={'index-3'}>
            <span className="semicircleorange"></span> <span>regx_hero_list_item_3</span>
          </li>
          <li key={'index-4'}>
            <span className="semicircleorange"></span> <span>regx_hero_list_item_4</span>
          </li>
      </ul>
      </div>

      <img decoding="async" loading="lazy" src="https://img.pristyncare.com/anoop.webp" data-hero=""height={'auto'} width={'120px'} ></img>

      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Edit Banner Styles</DialogTitle>
        <DialogContent>
        <div style={{display:'flex', flexDirection:'column', gap:'10px',borderRadius:'10px'}}>
          <TextField size="small" label="Height" name="height" value={bannerStyle.height || ''} onChange={handleStyleChange} />
          <TextField size="small" label="Width" name="width" value={bannerStyle.width || ''} onChange={handleStyleChange} />
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={applyStyles}>Apply</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BannerComponent;
