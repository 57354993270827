import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const FormComponent = (props:any) => {
  const handleSubmit = (e:React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
  };

  const [hoverState, setHoverState] = useState(false)

  const handleHover = (event:any)=>{
    event.stopPropagation()
    setHoverState(true)
  }
  const handleHoverOut = (event:any)=>{
    event.stopPropagation()
    setHoverState(false)
  }

  return (<div onMouseOver={(e) => handleHover(e)} onMouseOut={(e) => handleHoverOut(e)}>
    {<CloseIcon
        onMouseOver={(e) => handleHover(e)}
        className='templateCloseIcon'
        style={{
          position:'absolute',
          top: '-10px',
          display: `${!hoverState || props.dontShow ? "none" : "block"}`,
          right: '-10px',
          cursor: 'pointer',
          background: '#fff',
          borderRadius: '50%',
          padding: '3px',
          width:'25px',
          height:'25px',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',                     
        }}
        onClick={() => props.removeComponent()}
        />}
    <form onSubmit={handleSubmit} style={{ width: props?.width||'100%', boxShadow: '0 4px 24px hsla(0, 0%, 67.5%,.4)', borderRadius:'10px', padding:'2px 0px 30px', background:'rgba(216, 216, 216, 0.3)',
       
     }} >
      <h2 style={{textAlign:'center'}}>regx_form_title</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent:'center', alignItems:'center' }}>
        {/* <label htmlFor="name">regx_name </label> */}
        <input type="text" id="name" name="name" placeholder='regx_name' style={{height: '45px',
         border:'0.5px solid gray', borderRadius:'23px',padding:'10px',width:'320px'
      }}/>

        {/* <label htmlFor="email">regx_mobile</label> */}
        <input type="email" id="email" name="email" style={{height: '45px',
         border:'0.5px solid gray', borderRadius:'23px',padding:'10px',width:'320px'}} placeholder='regx_name' />

        {/* <label htmlFor="message">regx_message</label> */}
        <textarea id="message" name="message" rows={4} style={{border:'0.5px solid gray', borderRadius:'26px',padding:'10px',width:'320px'}} placeholder='regx_message'></textarea>

        <button type="submit" style={{height: '55px',
          background: '#3d7cc9',
          color: 'white',
          borderRadius:'23px',
          border: 'none',
          fontSize:'20px',
          fontWeight:700,width:'320px'}}>regx_hero_form_cta</button>
      </div>
    </form>
    </div>
  );
};

export default FormComponent;
