import { useEffect, useState } from "react"
import { useWebContext } from "../../_contexts"
import { useAxios } from "../../_utility"
import { assistDoctorListProps, assistDoctorListResponse, templateListProps, templateResponse } from "./types"

const apiUrl = `${process.env.REACT_APP_AMP_API_SLUG}`
const getTemplateUrl = `${apiUrl}/ampPages/getTemplates`
const uploadCsvUrl = `${apiUrl}/ampPages/creationRequest`
const downloadCsvUrl = `${apiUrl}/ampPages/viewTemplate/`
const hostedZoneUrl = `${apiUrl}/ampPages/getHostedZone`

export function useTemplateList(url:any) {
  const [templateList, setTemplateList] = useState<templateListProps[]>([])
  const { response, error, isLoading } = useAxios({
    url: url ? `${apiUrl}/ampPages/abTesting/getTemplates` :  getTemplateUrl,
    initFetch: true
  })

  useEffect(() => {
    const { result }: templateResponse = response || { status: '', description: '', result: { ampPagesTemplates: [] } }
    const { ampPagesTemplates } = result
    ampPagesTemplates.length !== 0 && setTemplateList(ampPagesTemplates.map((item) => {
      item.templateName = item.templateName || "Untitled"
      return item
    }))
    return () => {
      setTemplateList([])
    }
  }, [response, error])

  return { templateList, error, isLoading }
}

export function useAssistDoctorList(name:String) {
  const [assistDoctorList, setAssistDoctorList] = useState<assistDoctorListProps[]>([])
  console.log({name})
  const { response, error, isLoading } = useAxios({
    url: `${apiUrl}/ampPages/getDoctorsList?name=${name}`,
    initFetch: name !=''
  })

  useEffect(() => {
    const { result }: assistDoctorListResponse = response || { status: '', description: '', result: { assistDoctors : [] } }
    console.log({result})
    const {assistDoctors} = result
    assistDoctors.length !== 0 && setAssistDoctorList(assistDoctors)
    return () => {
      setAssistDoctorList([])
    }
  }, [response, error])

  return { assistDoctorList, error, isLoading }
}


export function useUploadCsv(url:any) {
  const { response, isLoading, error, fetch } = useAxios({
    url: url || uploadCsvUrl,
    method: 'post'
  })
  const { notification } = useWebContext()
  useEffect(() => {
    if (response && response.status === "Success") {
      notification("File Uploaded Successfully", "success")
    }
    if (error && error.status === "Failed") {
      notification(error.description, "error")
    }

  }, [response, error])
  return { response, isLoading, fetch }
}

export function useCsvDownload(templateId: string) {
  const { response, isLoading, error, fetch } = useAxios({
    url: downloadCsvUrl + templateId,
  })
  return { response, error, isLoading, fetch }
}

export function useGetHostedZone() {
  const { response, isLoading, error, fetch } = useAxios({
    url: hostedZoneUrl,
  })
  const [hostedZoneData, setHostedZoneData] = useState([])
  
  const { notification } = useWebContext()
  useEffect(() => {
    if (response && response.status === "Success") {
      if(response?.result){
        setHostedZoneData(response?.result)
      }
      else{
        setHostedZoneData([])
      }
      // notification("Fetched Hosted Zone Successfully", "success")
    }
    if (error && error.status === "Failed") {
      notification(error.description, "error")
    }

  }, [response, error])
  return { response, isLoading, fetch, hostedZoneData }
}

