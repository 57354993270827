import { FileDownloadRounded, FileUploadRounded, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CSVLink } from "react-csv";
import FileUploadDialog from "./FileUploadDialog";
import { useCsvDownload, useGetHostedZone } from "./templates.api";
import { templateListProps } from "./types";

const previewURL  = process.env.REACT_APP_StagingPreviewSlug

const TemplateViewItem: React.FC<templateListProps> = ({ _id, templateName, rawHtml = '' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isABTesting, setABTTesting] = useState(false)
  const [tooltipText, setTooltipText] = useState("Copy");
  const [csvData, setCsvData] = useState<any>([[]])
  const { response, isLoading, fetch } = useCsvDownload(_id)
  useEffect(() => {
    response?.result?.keys?.length > 0 && setCsvData([response?.result?.keys])
  }, [response])
  // editableIds.includes(id)?undefined:true
  const { response:hostResponse, isLoading:hostIsLoading, fetch:hostFetch,hostedZoneData } = useGetHostedZone()

const handleOpenFileUploader = () =>{
  hostFetch()
  setIsOpen(true)
}
 
  const handleCopy = (event:any) => {
    event.stopPropagation();
    event.preventDefault();
    navigator.clipboard.writeText(templateName)
      .then(() => {
        setTooltipText("Copied!");
        setTimeout(() => setTooltipText("Copy"), 2000);
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      <Card 
      // sx={{ pointerEvents:disableIds.includes(_id)?'none':'',opacity:disableIds.includes(_id)?"0.6":''}}
       key={_id} data-component="TemplateViewItem">
        <CardActionArea href={previewURL+rawHtml} target={'_blank'} title={_id} sx={(theme => ({
          backgroundColor: (theme.palette.mode === 'light' ? "#f5f5f5" : "#646464")
        }))} >
          <CardContent>
            <Typography gutterBottom variant="h6" component="h4" sx={{fontSize:'1em'}}>{templateName}
            <Tooltip title={tooltipText} placement="top">
            <IconButton onClick={handleCopy} onMouseDown={event => event.stopPropagation()}>
              <ContentCopyIcon sx={{height:'16px', width:'16px'}}/>
            </IconButton>
            </Tooltip>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: 'space-between' }} >
          <Link href={previewURL+rawHtml} target={'_blank'} underline="none">
            <Button size="small" color="info">
              <Visibility sx={{ mr: 1 }} /> View
            </Button>
          </Link>
          <Box>
            {
              csvData[0].length > 0 ?
                <CSVLink
                  data={csvData}
                  filename={templateName}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Button size="small" variant="contained" color="success" title="Upload Data"><FileDownloadRounded /> Ready To download</Button>
                </CSVLink>
                :
                <LoadingButton variant="contained" loading={isLoading} onClick={() => { fetch() }} size="small" color="warning" title="Download Stucture"><FileDownloadRounded /></LoadingButton>
            }
            <Button size="small" variant="contained" color="primary" title="Upload Data" onClick={handleOpenFileUploader} sx={{ ml: 2 }}><FileUploadRounded /></Button>
            {/* {window.location.href.includes('/customTemplate') && <Button size="small" variant="contained" color="primary" title="Upload Data for A/B testing" onClick={() => {
              setABTTesting(true)
              setIsOpen(true)
              return
            } } sx={{ ml: 2 }}>A/B</Button>} */}
          </Box>
        </CardActions>
      </Card>
      <FileUploadDialog id={_id} title={templateName} isOpen={isOpen} isAbTesting={isABTesting} hostedZoneData = {hostedZoneData} handleClose={() => {
        setIsOpen(!isOpen)
      // setABTTesting(!isABTesting) 
      }} />
    </>
  )
}
export default TemplateViewItem